/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body,
a,
p,
div,
ng-container,
th,
td,
tr,
b,
input,
mat-button,
mat-select,
mat-option,
mat-form-field,
mat-datepicker-toggle,
ngx-mat-datetime-picker,
mat-checkboxmat-radio-group,
mat-radio-button,
mat-slide-toggle,
mat-label,
mat-toolbar{ 
    margin: 0; 
    font-family: Montserrat, "Montserrat", sans-serif !important;
    // background-color: black;
    
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
